import React, { useState } from "react";
import { navigate } from "gatsby";
import { Formik, Form } from "formik";

import { base, formContainer, formInput, formSubmit, message, copy } from "./DemoForm.module.scss";

import FormInput from "../components/FormInput";
import Button from "../components/Button";
import Link from "../components/Link";

import countries from "../utils/countries";
import { submitDemoForm } from "../utils/api";
import { isValidEmail } from "../utils/misc";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  userType: "",
  country: "",
};

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "This field is required";
  }

  if (!values.lastName) {
    errors.lastName = "This field is required";
  }

  if (!values.email) {
    errors.email = "This field is required";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.phone) {
    errors.phone = "This field is required";
  }

  if (!values.userType) {
    errors.userType = "This field is required";
  }

  if (!values.country) {
    errors.country = "This field is required";
  }
};

const Message = props => {
  const { heading, body } = props;

  return (
    <div className={message}>
      <h3>{heading}</h3>
      <p>{body}</p>
    </div>
  );
};

const DemoForm = props => {
  const [success, setSuccess] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    const url = `${values.country}?firstname=${values.firstName}&lastname=${values.lastName}&email=${values.email}&mobilephone=${values.phone}&stakeholder=${values.userType}`;
    navigate(encodeURI(url));

    // setTimeout(() => {
    //   submitDemoForm(values)
    //     .then(() => {
    //       setSubmitting(false);
    //       setSuccess(true);
    //       setTimeout(() => setSuccess(null), 6000);
    //     })
    //     .catch(() => {
    //       setSubmitting(false);
    //       setSuccess(false);
    //       setTimeout(() => setSuccess(null), 4000);
    //     });
    // }, 400);
  };

  if (success === true) {
    return (
      <Message
        heading="Success!"
        body="Thank you for connecting with us. A member of our team will be in touch with you."
      />
    );
  }

  if (success === false) {
    return (
      <Message
        heading="Oops, something went wrong!"
        body="Please check your details and try again."
      />
    );
  }

  return (
    <div className={base}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form className={formContainer}>
            <FormInput name="firstName" label="First name" className={formInput} />
            <FormInput name="lastName" label="Last name" className={formInput} />
            <FormInput name="email" label="Email" className={formInput} />
            <FormInput name="phone" label="Phone number" className={formInput} />
            <FormInput
              name="userType"
              label="I'm a"
              type="select"
              options={[
                { value: "", label: "" },
                { value: "partner", label: "Partner" },
                { value: "brand", label: "Brand" },
                { value: "agency", label: "Agency" },
                { value: "investor", label: "Investor" },
                { value: "other", label: "Other" },
              ]}
              className={formInput}
            />
            <FormInput
              name="country"
              label="Region"
              type="select"
              options={[
                { value: "", label: "" },
                {
                  label: "Australia & New Zealand",
                  value:
                    "https://content.brandcrush.com/meetings/brandcrush-demo/book-a-demo-australia-new-zealand-",
                },
                {
                  label: "North America",
                  value: "https://meetings.hubspot.com/ana63/book-a-demo-usa-and-canada",
                },
                {
                  label: "Europe & United Kingdom",
                  value: "https://content.brandcrush.com/meetings/brandcrush-demo/book-a-demo-uk",
                },
              ]}
              className={formInput}
            />
            <small className={copy}>
              We're committed to your privacy. Brandcrush uses the information you provide to us
              to contact you about our relevant content, products, and services. You may
              unsubscribe from these communications at any time. For more information, check out
              our <Link href="/privacypolicy">Privacy Policy</Link>.
            </small>
            <div className={formSubmit}>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DemoForm;
