import React from "react";

import { base } from "./Quote.module.scss";

import { combine, getClassNameModifiers } from "../utils/classNames";

const Quote = props => {
  const { className, quote, author, color } = props;

  const modifiers = getClassNameModifiers(props, ["color"]);

  const shouldUseWhiteIcon = color === "blue" || color === "navy" || color === "cerise";

  return (
    <div className={combine(base, modifiers, className)}>
      <img src={shouldUseWhiteIcon ? "/images/quote-white.svg" : "/images/quote.svg"} alt="" />
      <p>{quote}</p>
      <p>{author}</p>
    </div>
  );
};

export default Quote;
