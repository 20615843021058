import React, {useEffect} from "react";
import { navigate } from "gatsby";

import { demoForm, quote } from "./book-a-demo.module.scss";

import Layout from "../layouts/Layout";
import Quote from "../components/Quote";
import Button from "../components/Button";
import DemoBanner from "../partials/DemoBanner";
import DemoForm from "../partials/DemoForm";

const BookADemoPage = props => {

  // Remove after fix SUP-1282
  useEffect(()=>{
    navigate('/contact')
  })

  return (
    <Layout
      pageTitle="Schedule a demo | Brandcrush"
      ogTitle="Brandcrush: Schedule a Demo"
      ogDescription="Book a demo with the Brandcrush team to get a first-hand tour of the platform. See how everything works, and get insider tips and tricks."
    >
      <DemoBanner />
      <section className={demoForm}>
        <DemoForm />
        <Quote
          className={quote}
          quote="We can authentically introduce brands to millions of highly targeted and attractive households each week through our parcels, our insert perks as well as our social and digital channels. We needed a platform to streamline and scale our media sales. With Brandcrush we now have one simple platform to manage it all. It helps us manage our unique media inventory and workflow requirements and give us the tools to maximize our revenue potential."
          author="Louise Branth, Commercial Partnerships, Hellofresh United Kingdom."
          color="blue"
        />
      </section>
    </Layout>
  );
};

export default BookADemoPage;
