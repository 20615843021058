import React from "react";

import { base } from "./DemoBanner.module.scss";

const DemoBanner = props => {
  return (
    <div className={base}>
      <h1>Book a call</h1>
      <p>
        <b>Partners:</b> discover how businesses are using Brandcrush to monetize and maximize their
        owned media assets.
        <br />
        <br />
        <b>Brands:</b> meet with our team to explore the partner network and scale high-engagement
        campaigns.
      </p>
      <img src="/images/demo-graphic.svg" alt="" />
    </div>
  );
};

export default DemoBanner;
